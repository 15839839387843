
import {
    DxDataGrid,
    DxEditing,
    DxColumn,
    DxPaging,
    //DxLookup,
    //DxForm,
    DxFilterRow,
    DxSearchPanel,
    DxScrolling,
    DxPager,
    DxValidationRule,
    DxFormItem,
    DxButton
} from 'devextreme-vue/data-grid';
//import { DxItem } from 'devextreme-vue/form';
import { DxToast } from 'devextreme-vue/toast';
 import 'devextreme-vue/text-area';
 import DxPopup, { DxToolbarItem }from 'devextreme-vue/popup';
import DxScrollView from 'devextreme-vue/scroll-view';
import { DxTextBox } from 'devextreme-vue/text-box';
import DxTextArea from 'devextreme-vue/text-area';
import DxSelectBox from 'devextreme-vue/select-box';
import EstatusService from '../services/estatus-service';
import SolicitudServicioService from '../services/solicitudservicio-service';
//import DxButton from 'devextreme-vue/button';
import notify from 'devextreme/ui/notify';
import Cookies from "js-cookie";
window.Cookies = Cookies;
export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxPaging,
        DxEditing,
        //DxLookup,
        //DxForm,
        //DxItem,
        DxFilterRow,
        DxSearchPanel,
        DxScrolling,
        DxPager,
        DxToast,
        DxValidationRule,
        DxFormItem,
        DxButton,
        DxPopup,
        DxScrollView,
        DxToolbarItem,
        DxTextBox,
        DxTextArea,
        DxSelectBox
    },
    props: {
    supplierId: {
      type: Number,
      default: null,
    },
  },
    data() {
        
        return {
            
            procedimientos: null,
            dataSourceEstatus: null,
            dataSourceEstatusEdicion:null,
            dataSourceSolicitudServicio:null,
            allowedPageSizes: [5, 10, 20, 50, 'all'],
            showInfo: true,
            showNavButtons: true,
            isVisible: false,
            message: '',
            type: 'info',
            popupOptions: {
                title: 'Solicitud Servicio',
                showTitle: true,
                width: 400,
                height: 500,
            },
            frm_id:null,
            frm_estatusactual: null,
            frm_estatusnuevo: null,
            frm_observaciones: null,
            popupVisible:false,
            popupTitle: "Cambio Estatus",
            loading: true,
           
            
        };
    },
    created() {
        
        this.solicitudservicioService = new SolicitudServicioService();
        this.estatusService = new EstatusService();

    },
    mounted() {
        
        this.loadDataInfo();
    },
    methods: {
        loadDataInfo() {
            let id = this.supplierId;
            let myJSON = JSON.stringify({
                psolicitudid: id
            });
            
            
             //this.estatusService.getEstatus().then(data => this.dataSourceEstatus = data);
            
            this.solicitudservicioService.getSolicitudServicio(myJSON).then(data => this.dataSourceSolicitudServicio = data);
        },
            editEstatus(e) {
             
            let myJSON = JSON.stringify({
                ptipo: 1,
            });
            
            this.estatusService.getEstatusParaEdicion(myJSON).then(data => this.dataSourceEstatusEdicion = data);
              
            this.frm_id = e.row.data.id;
            this.frm_estatusactual = e.row.data.estatus;
            this.frm_estatusnuevo = null;
            this.frm_observaciones = null;
            
            this.loading = true;      
            this.popupVisible = true;
        
        },
        onSaved() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        onEditCanceled() {
            if (this.retryButtonVisible) {
                this.retryButtonVisible = false;
            }
        },
        logEvent(eventName) {
            this.events.unshift(eventName);
        },
       
       
         editRegister() {
            
            let idusuario = JSON.parse(Cookies.get("ets_user_ec")).id;//1;
            let myJSON = JSON.stringify({
                 pid: this.frm_id,
                 pestatusid: this.frm_estatusnuevo,
                 pobservaciones: this.frm_observaciones,
                 pusuariocancelacionid:idusuario,
                 pidsolicitud:this.supplierId,
            });
            
            let err = 0;
            let msgerror = '';

            if(this.frm_id == null){
                err = 1;
                msgerror = 'Seleccionar registro';
            }  

            if(err == 0){
                if(this.frm_estatusnuevo == null){
                    err = 1;
                    msgerror = 'Seleccionar estatus nuevo';
                }
            }
            
          
    
            if(err == 0){
                if(this.frm_observaciones == null){
                    err = 1;
                    msgerror = 'Ingresar observaciones';
                }
            }

           

            if(err==0){
                            this.solicitudservicioService.updateSolicitudServicioEstatus(myJSON).then(data => {

                            if (data.err == 0) {
                            this.type = 'success';
                            this.message = 'Se ha guardado el registro';
                            this.isVisible = true;

                            notify({
                                message: this.message,
                            }, this.type, 5000);
                            this.popupVisible = false;
                            this.loadDataInfo();
                            
                           
                            //this.$router.push('solicitud')
                            } else {

                            this.type = 'error';
                            this.message = 'Ocurrio un error ' + data.msg;
                            this.isVisible = true;
                            notify({
                                message: 'Ocurrio un error ' + data.msg,
                            }, this.type, 4000);

                            
                            }
                            //this.loadDataInfo();

                            });
                        }else{
                            this.type = 'error';
                            this.message = msgerror;
                            this.isVisible = true;
                            notify({
                                message: this.message,
                            }, this.type, 4000);

                        }

        },
        
        onInitNewRow(e) {
            e.data.bactivo = true;
        },
    },
};
